.question__section {
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    margin-bottom: 16px;
    padding: 20px;
}

.active__question__section {
    border-bottom: none;
}

.question__section .question__wrapper {
    width: 100%;
}

.question__section .question__wrapper .question__container {
    cursor: pointer;
    width: 100%;
}
.question__section .question__wrapper .question__container .question{
    width: 97%;

}

.question__section .question__wrapper .question__container .question p ,.question__section .question__wrapper .question__container .question span{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0;

}

.question__section .question__wrapper .question__container .question span{
    margin-right: 5px;
    float: left;
}

.question__section .question__wrapper .question__container  svg {
    stroke-width: 1.5px;
    color: #bababa;

}

.add__choice__section {
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 15px;
    margin-top: 15px; 
    margin-bottom: 15px;
}
.checkbox__and__delete__choice__section{
    margin-top: -6px;
}
.checkbox__and__delete__choice__section .choice__delete__btn{
    all: unset;
    display: flex;
    align-items: center;
    color: #685F78;
/* font-family: Noto Sans; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    /* E53935 */

}
.checkbox__and__delete__choice__section .choice__delete__btn:hover,.checkbox__and__delete__choice__section .choice__delete__btn:hover svg{
    color: #E53935;

}
.checkbox__and__delete__choice__section .choice__delete__btn svg{
    stroke-width: 1.5px;
    width: 18px;
    color: #BABABA;
    margin-right: 10px;
    
}
.radio__checkbox__wrapper{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.checkbox__and__delete__choice__section input[type="radio"],.checkbox__and__delete__choice__section input[type="checkbox"]{
    height: 15px;
    width: 15px;
    cursor: pointer;
    accent-color: #FF5364;
}
.checkbox__and__delete__choice__section label{
    margin-bottom: 0px;
    cursor: pointer;
    color: #685F78;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.add__choice__btn{
    margin-top: 10px;
}