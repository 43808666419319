.sc__select__wrapper label{
    color: #685F78;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 
    margin-bottom: 6px;

}
.sc__select__wrapper select{
    padding: 12px 10px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    color: #263238;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
