.sc_learning_path_course_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
}
.sc_learning_path_course_title .title_section {
  display: flex;
  align-items: center;
}

.sc_learning_path_course_title .title_section .title {
  color: #22100d;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 150% */
  cursor: pointer;
  display: block;
  margin-left: 8px;
  padding: 20px 0;
}

.sc_learning_path_course_title .title_section .title:hover {
  color: #ff5364;
}
.sc_learning_path_course_title .delete_icon {
  color: #bababa;
  cursor: pointer;
}
