.sc_button {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.sc_primary_button {
  color: #fff;
  background: #1d9cfd;
}

.sc_primary_button:hover {
  color: #1d9cfd;
  background-color: #ffffff;
  border-color: #1d9cfd;
}
.sc_danger_button {
  color: #fff;
  background: #f04438;
}

.sc_danger_button:hover {
  color: #f04438;
  background-color: #ffffff;
  border-color: #f04438;
}
.sc_text_button {
  all: unset;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.sc_text_primary_button {
  color: #1d9cfd;
}
.sc_text_danger_button {
  color: #f04438;
}
.sc__resend__invitation__btn{
  all: unset;
  background: #392C7D;
  padding: 6px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
}
.sc__resend__invitation__btn:hover{
  background: #392c7d;
  opacity: 0.86;
}
