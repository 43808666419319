.slides__section .slides__wrapper {
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 20px;

}

.slides__section .slides__wrapper .header__section p {
    color: #000;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

}

.slides__section .slides__wrapper .header__section span svg {
    color: #BABABA;
    cursor: pointer;
}

.slides__container .slide__div {
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 20px 15px;
    cursor: pointer;

}

.slide__wrapper .slide__count {
    color: #263238;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.slide__wrapper span svg {
    color: #BABABA;
}
.pdf__content__wrapper{
    border: 1px solid #E9ECEF;
}
.pdf__content__wrapper .expand__pdf{
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.pdf__content__wrapper .expand__pdf svg{
    color: #BABABA;
    margin: 12px;
}

.pdf__section p,
.audio__section p {
    color: #685F78;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;

}
.audio__section p span {
    color: #A2A2A2;
}
.audio__content__wrapper .buttons__wrapper button {
    width: 50%;
}

.audio__content__wrapper .buttons__wrapper {
    gap: 8px;
}

.audio__content__wrapper .buttons__wrapper .audio__Upload__btn {
    border: 1px solid #1D9CFD;
    background: transparent;
    color: #1D9CFD;
    border-radius: 5px;
    padding: 8px 0;
    cursor: pointer;
    text-align: center;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: 0.3s;

}

.supprts__text {
    margin-top: 15px;
    color: #A2A2A2;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.audios__list {
    max-height: 400px;
    overflow-y: auto;

}

.audios__list::-webkit-scrollbar {
    display: none;
}

.audios__list .audio audio {
    width: 100%;
    margin-top: 10px;
}

.audios__list .audio .audio__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.audios__list .audio .audio__name {
    color: #7D7D7D;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

}

.audios__list .audio .audio__delete svg {
    color: #7D7D7D;
    height: 18px;

}

.sc__upload__audio__progress {
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    padding: 18px 15px;
}

/* viwe pdf in big screen  */
.lg__pdf__wrapper{
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0, 0, 0, 0.70);
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

}

.lg__pdf__wrapper .lg__pdf__container .goback{
    position: absolute;
    left: 60px;
    top: 10px;
    color: #BABABA;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;

}

.lg__pdf__wrapper .lg__pdf__container .lg__pdf::-webkit-scrollbar{
    display: none;
}