.audio__records__actions{
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 16px 15px;
    display: flex;
    justify-content: space-between;

}
.audio__records__actions .left__actions{
    display: flex;
    align-items: center;
    gap: 15px;
}
.audio__records__actions .left__actions .audio__action__btn{
    padding: 8px 14px;
    border-radius: 5px; 
    background: #1D9CFD;
    color: #FFF;
    text-align: center;
    /* Text sm/Medium */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer; 
    border: none;
    outline: none;

}
.audio__records__actions .left__actions .audio__action__btn.play__btn {
    border: 1px solid #1D9CFD;
    background: #FFF;
    color: #1D9CFD;
}
.audio__records__actions .audio__record__timer span{
    color: #263238;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.right__actions > div{
    display: flex;
    align-items: center;
    gap: 15px;
}
.right__actions > div span{
    color: #685F78;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.right__actions > div span svg{
    color: #685F78;
    height: 18px;
    margin-top: -3px;

}
.view__pdf__wrapper{
    margin-top: 20px;
}
.view__pdf__wrapper .view__pdf__container{
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}
.document__custom__className .react-pdf__Page__canvas{
    padding: 0;
}

/* File saving progress css START */
.saving__progress__wrapper{
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
/* File saving progress css END */