.sc__modal{
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000 !important;
}
.sc__modal.active{
    opacity: 1;
    visibility: visible;
}
.sc__modal.active .sc__modal__content__wrapper{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.sc__modal__content__wrapper{
    max-height: calc(100vh - 30px);
    border-radius: 5px;
    position: relative;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    padding: 24px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 8px;
    background: #fff;
    width: 660px;
    max-width: 90%;

}
.sc__modal__content__wrapper::-webkit-scrollbar{
    display: none;
}

.sc__modal__close__icon{
    position: absolute;
    right: 25px;
    color: #bababa;
    cursor: pointer;

}
.sc__modal__header p{
    color: #000;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.sc__modal__body{
    /* width: 660px; */
    /* max-width: 90%; */
    width: 100%;
}