.sc_full_search_div {
  position: relative;
}

.sc_full_search_div .search_icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.sc_full_search_div input {
  padding-left: 35px;
}
