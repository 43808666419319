.mr-1 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 50px;
}
.mr-10 {
  margin-right: 100px;
}
.marginTop-1 {
  display: block;
  margin-top: -20px;
}
.marginTop-0 {
  margin-top: 0 !important;
}
.marginTop-13 {
  margin-top: -13px !important;
}
.marginTop-20 {
  margin-top: -20px !important;
}
.width-40 {
  width: 40px;
}
.height-40 {
  height: 40px;
}
.sc_black {
  background: #000 !important;
  border: 1px solid #000;
}
.sc_black:hover {
  border: 1px solid #000;
}
.sc_black:focus {
  border: 1px solid #000;
}

.two_lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.three_lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.sc_learnig_path_cover_image {
  width: 180px;
  object-fit: cover;
  height: 180px;
}
.marginBotom30 {
  margin-bottom: -30px;
}
.sc_success {
  background-color: #159f46 !important;
  font-size: 14px;
  font-weight: 600;
}
.sc_user_avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}
.sc_nav_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sc_right_nav_section {
  display: flex;
  align-items: center;
}
.sc_nav_list_right_margin {
  margin-right: 2rem;
}
.sc_basic_course_file_upload_section {
  width: 100%;
  height: 15rem;
}
.sc_lesson_file_upload_height {
  height: 10rem;
}

.sc_file_upload_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sc_upload_file_description {
  margin-top: 1rem;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}
.mb--05 {
  margin-bottom: -5px;
}
.sc_upload_file_description .second_desc {
  display: block;
}
.sc_upload_file_colorful_text {
  color: #f66962;
}
.sc_show_selected_file_section {
  width: 100%;
  height: 100%;
}
.sc__selcted__file {
  max-width: 100%;
  max-height: 12rem;
}
.sc_delete_text_button {
  all: unset;
  color: #f04438;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
}
.sc_delete_text_button .delete__icon {
  display: block;
  margin-right: 3px;
  margin-top: -3px;
}
.sc_delete_button_wrapper {
  display: block;
  margin-top: 8px;
}
.sc_add_ciriculum_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.7rem;
}
.sc_add__lesson__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sc_add__lesson__section .lesson_name {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.sc_lesson_button__section {
  color: #bababa;
}
.sc_lesson_button__section .edit__icon {
  margin-right: 10px;
  cursor: pointer;
}
.sc_lesson_button__section .trash_icon {
  cursor: pointer;
}

.sc_add_lesson_button_row {
  display: flex;
  justify-content: flex-end;
}
.sc_add_lesson_image_section {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sc_add_lesson_image_section img {
  margin-left: -20px;
  width: 8rem;
}

.sc_add_lesson_image_section span {
  margin-top: 10px;
  text-align: center;
}
.sc_lesson_title {
  font-weight: 500;
  font-size: 16px;
}

.sc_modal_content {
  padding: 24px;
  word-break: break-word;
  background: #ffffff;
  width: 660px;
  max-width: calc(100vw - 10rem);
  max-height: calc(100vh - 2rem);
  overflow-x: auto;
  position: relative;
  border-radius: 12px;
  box-shadow:
    0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16,  24,  40,  0.08);
}
.sc_delete_modal_content {
  /* padding-top: 24px; */
  width: 451px;
  padding: 0;
  padding-top: 24px;
}
.sc_overlay {
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.sc_modal_close_icon {
  position: absolute;
  right: 20px;
  top: 15px;
  color: #bababa;
  cursor: pointer;
}
.sc_modal_title {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  font-family: Inter;
}
.sc_delete_modal_title_section {
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.sc_modal_danger_icon {
  margin-right: 10px;
  margin-top: -2px;
}
.sc_modal_button_section {
  display: flex;
  justify-content: flex-end;
}
.sc_modal_delete_description {
  display: block;
  color: #344054;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-left: 55px;
  max-width: 370px;
}
.sc_delete_modal_footer {
  background: #fafafa;
  margin-top: 24px;
  padding: 10px 24px 24px 24px;
}

.sc_hide_scrollbar::-webkit-scrollbar {
  /* width: 7px; */
  display: none;
}
.sc_hide_scrollbar::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: red;
}
.sc_hide_scrollbar::-webkit-scrollbar-track {
  background-color: gray;

  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.sc_instructor_table_action {
  display: flex;
  justify-content: flex-end;
  border: none;
  margin-top: 13px;
}

.sc_instructor_table_action .icon {
  cursor: pointer;
  color: #bababa;
}
.sc-border-bottom {
  border-bottom: 1px solid #dee2e6;
}
.sc_error__text {
  font-size: 14px;
  color: #f04438;
  font-weight: 400;
  margin-top: 5px;
}

.course-stip.progress-stip.file-upload {
  height: 3px;
}
.sc_active__bg {
  background-color: #1d9cfd !important;
}

.sc_divider {
  display: block;
  border-top: 1px solid #e9ecef;
}
.sc_show_uploading_precentage {
  color: #7d7d7d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 166.667% */
}
.sc_uploaded_file_name {
  color: #7d7d7d;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.sc_cancel_upload__icon {
  color: #000000;
  cursor: pointer;
}
.sc_course_image_in_table {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.noti-nav .dropdown-toggle:after {
  display: none;
}
.sc_learning_path_divider {
  color: #212529;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px; /* 150% */
  background: #f0f0f0;
  padding: 10px;
  margin-top: -20px;
  margin-bottom: 30px;
}
.sc_textarea {
  min-height: 10rem !important;
}

.sc_learning_path_course_list_section {
  margin-top: 20px;
  border-radius: 8px;
  background: #f9fafb;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  max-height: 300px;
  overflow: auto;
}

.sc_learning_path_course_list_section span {
  display: block;
  color: #22100d;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding: 7px;
  cursor: pointer;
}
.sc_learning_path_course_list_section span:hover {
  color: #f66962;
}
.spin {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
