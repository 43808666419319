.loader__wrapper__style{
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.loader__wrapper__style .para__style{
    color: #7D7D7D;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 40px;
    text-transform: none;
}
.text__style{
    color: #7D7D7D;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
}