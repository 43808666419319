.your__avatar{
    color: #392c7d;
    font-size: 18px;
    font-weight: 600;
}

/* FOR COURSE DETAILS CSS STARTS HERE  */
.course__title{
    cursor: pointer;
}
.course__title:hover{
    color: #f66962;
}
.courses__title{
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    color: #212529;
    text-transform: uppercase;
}
.course__card__img__style{
    height: 180px;
    object-fit: cover;
}
.lessons__count{
    color: #392C7D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.video__section{
    max-width: 100%;
    max-height: 420px;
}
.video__introduction{
    color: #F66962;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
}
.card__title__box__style{
    border-bottom: none;
    border-radius:10px 10px 0px 0px  !important;
}
.card__margin{
    margin-top: -1rem;
    border-top: none;
    border-radius:0px 0px 10px 10px !important;
}
.course__content__title{
    display: flex !important;
}
.course__body__style:hover, .course-card ul .course__content__title:hover,.course__title:hover{
    cursor: pointer;
}
.course__body__style:hover .chapter__name,.course-card ul .course__content__title:hover,.active__title,.course__title:hover .rating-img p{
    color: #F66962;
}
/* FOR COURSE DETAILS CSS END HERE  */

/* FOR PDF VIEWER CSS STARTS HERE  */

.react-pdf__Page__canvas{
  background: #FAFAFA;
  padding: 50px;
}
.download__button{
    width: 25% !important;
}
@media screen and (max-width:767px) {
    .download__button{
        width: 50% !important;
    }

    
}
/* FOR PDF VIEWER CSS END HERE  */

/* PROFILE SECTION CSS STARTS HERE */
.profile__picture__img__wrapper{
    max-width: 100px;
    width: 100%;
    height: 100px;
    margin-right: 10px;
    
}
.profile__picture__img__wrapper img{
    max-width: 100px;
    width: 100%;
    height: 100px;
    object-fit: cover;
}
@media screen and (max-width:1000px) {

    .profile__picture__img__wrapper img{
        max-width: 100px;
        width: 100%;
        height: 100px;
    }
}

@media screen and (max-width:767px) {
    .profile__picture__img__wrapper{
        max-width: 60px;
        width: 100%;
        height: 60px;
    }
    
}
/* PROFILE SECTION CSS END HERE */

/* custom loader css starts here  */

.loader__wrapper p {
    margin-top: 30px;
    margin-left: 18px;
    color: #575757;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;

}

.custom__loader {
    position: relative;
}

.custom__loader span:nth-child(1) {
    position: absolute;
    height: 14px;
    width: 14px;
    background: var(--firstDot);
    border-radius: 50%;
    z-index: 2;
    opacity: 0;
}
.custom__loader span:nth-child(2) {
    position: absolute;
    height: 8px;
    width: 8px;
    background: #111827;
    border-radius: 50%;
    transform: translate(20px,15px);
    animation: animate 2s ease-in-out infinite;
    animation-delay: 0.3s;
    z-index: 1;
}
.custom__loader span:nth-child(3) {
    position: absolute;
    height: 8px;
    width: 8px;
    background: #111827;
    border-radius: 50%;
    transform: translate(-15px,15px);
    animation: animate 2s ease-in-out infinite;
    animation-delay: 0.3s;
    z-index: 1;
}
.custom__loader span:nth-child(4) {
    position: absolute;
    height: 8px;
    width: 8px;
    background: #111827;
    border-radius: 50%;
    transform: translateY(-16px);
    margin-left: 1px;
    animation: animate 2s ease-in-out infinite;
    animation-delay: 0.3s;
    z-index: 1;
}

@keyframes animate {
    100%{
        transform: translate(0);
        height: 14px;
        width: 14px;
        background: #FF4667;
    }
}

.api__setting__action__icons{
    display: flex;
    gap: 15px;
    position: absolute;
    right: 15px;
    top: 8px;
}
.icon__span{
    color: #6e82a3;
    cursor: pointer;
}
.api__setting__save__btn{
    position: absolute;
    right: 0px;
    top: 0px;

}
.api__setting__save__btn button{
    border-radius: 5px;
    border: 1px solid #392C7D;
    background: #392C7D;
    padding: 8px 31px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
}
.api__setting__save__btn button:hover{
    opacity: 0.86;
}
.nav-link:focus,.nav-link:focus{
    color: #303030;
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson{
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 20px 10px;
    margin-top: 20px;
    cursor: pointer;

}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson:hover,.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson:hover .sc__lesson__type{
    background: #FFF7F6;
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__type{
    height: 55px;
    width: 55px;
    border-radius: 5px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__type svg{
    stroke-width: 1.5px;
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__type img{
    /* width: 25px; */
    height: 25px;
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__info{
    
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__info span{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    
}
.sc__add__lessons__wrapper .sc__add__lessons__container .sc__lesson__info p{
    color: #7D7D7D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

 /* Add video lesson dropdown style  */

  .sc__dropdown__container{
    position: relative;
    user-select: none;
    cursor: pointer;
  }
  .sc__dropdown__container .sc__dropdown__select{
    position: relative;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    border: 1px solid #E9ECEF;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 12px;
    color: #263238;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .sc__dropdown__container .sc__dropdown__select svg{
    stroke-width: 1.5px;
    color: #BABABA;
  }
  .sc__dropdown__container .sc__dropdown__content{
    position: absolute;
    border: 1px solid #E9ECEF;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    z-index: 2;
    margin-top: 8px;
    
  }
  .sc__dropdown__container .sc__dropdown__content .sc__dropdown__item{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 12px 10px;

  }
  .sc__dropdown__container .sc__dropdown__content .sc__dropdown__item:hover{
    background: #FFF7F6;
  }

  .action__dropdown__wrapper{
    position: relative;
    z-index: 2;

  }
  .action__dropdown__wrapper span{
    all: unset;
    padding: 15px 5px;
    display: block;
  }

  .action__dropdown__wrapper span svg{ 
    cursor: pointer;
    
  }
  .action__dropdown__wrapper .action__drondown{
    all: unset;
    position: absolute;
    width: 200px;
    padding: 12px 0;
    border: 1px solid #E9ECEF;
    background: #fff;
    z-index: 4;
    left: -200px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    top: 35px;
    left: -195px;
  }
  
  .action__dropdown__wrapper .action__drondown button{
    all: unset;
  }
  .action__dropdown__wrapper .action__drondown  button{
    all: unset;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 5px 15px;
    cursor: pointer;
  }
  .action__dropdown__wrapper .action__drondown button:hover{
    background: #FFF7F6;;
  }
  .wrapper__action{
    all: unset;
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    padding: 25px;
    padding-top: 65px;
    padding-bottom: 16px;
    height: calc(100% - 81px);
  }